<template>
  <div v-if="item" class="template-pill" :class="{ hasItem: item }">
    <div class="columns is-mobile is-vcentered" style="">
      <!-- Image -->
      <div v-if="!noImage" class="column is-narrow">
        <img :src="item.image" alt="T " class="tb" />
      </div>

      <!-- Author Information -->
      <div class="column">
        <p class="byline" style="font-size: 12px">
          <img
            :src="item.avatar || item.image"
            class="avatar"
            loading="lazy"
            style="margin-right: 10px; width: 14px; height: 14px"
          />
          <router-link :to="'/' + item.bot">
            <span>{{ item.bot }}</span>
          </router-link>
        </p>

        <p class="subtitle t is-6">{{ item.name }}</p>
      </div>

      <!-- Close Icon -->
      <div v-if="closable" class="column is-narrow">
        <button @click="emitClear" class="delete"></button>
      </div>
    </div>
  </div>

  <!-- Empty State -->
  <div v-else class="empty-state" style="opacity: 0.5">
    <span class="icon has-text-grey-lighter">
      <i class="fas fa-ban"></i>
    </span>
    <p>No template selected</p>
  </div>
</template>

<script>
export default {
  name: "TemplatePill",
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    noImage: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    avatar() {
      // Return an avatar only if an item is provided
      return this.item ? `/path/to/avatars/${this.item.bot}.png` : "";
    },
  },
  methods: {
    emitClear() {
      this.$emit("clear");
    },
  },
};
</script>

<style scoped>
/* Optional: Add your styles here. You can also integrate Bulma classes */
.empty-state {
  padding: 10px 0px;
}

.tb {
  border-radius: 4px;
}

.delete {
  padding: 3px;
  font-size: 13px;
  background: transparent;
}

.t {
  font-weight: 400;
}

.delete::after,
.delete::before {
  background: #00000033;
}

.hasItem {
  background: transparent;
  background: #ffffff44;
  border-radius: 9px;
  padding: 10px;
  margin-bottom: 10px;
  min-width: 200px;
  width: fit-content;
}

.hasItem:hover {
  background: #ffffff88;
}
</style>
