<!-- BotTemplateForm.vue -->
<template>
  <div>
    <headerManage
      title="Name & description"
      :sub="localTemplate.name ? localTemplate.name : 'Not set'"
      desc="How this tools appears in lists everywhere."
      @save="submitForm"
      :posting="posting"
    >
      <!-- Here is where you define the content of the slot -->
      <div>
        <b-field label="Name:">
          <b-input v-model="localTemplate.name"></b-input>
        </b-field>

        <b-field label="Description:">
          <b-input
            v-model="localTemplate.desc"
            type="textarea"
            placeholder="This text will help people find your image generator template"
          ></b-input>
        </b-field>
        <b-field label="Placeholder text:">
          <b-input
            v-model="localTemplate.placeholder"
            type="textarea"
            placeholder="Text shown in empty input box"
          ></b-input>
        </b-field>
        <b-field label="Preview image URL:">
          <b-input v-model="localTemplate.image"></b-input>
        </b-field>

        <b-field label="Input Examples:">
          <b-input type="textarea" v-model="localTemplate.inputExamples" size="is-small"></b-input>
        </b-field>
      </div>
    </headerManage>

    <headerManage
      title="Prompt"
      :sub="
        localTemplate.promptSystem || localTemplate.promptPrefix
          ? localTemplate.promptSystem || localTemplate.promptPrefix
          : '⚠️ Not set'
      "
      desc="Define the prompt that will be used to generate images."
      @save="save"
      :posting="posting"
    >
      <div>
        <b-field label=" ">
          <b-switch v-model="localTemplate.useGpt">
            <!-- d-->
            Boost
            <span class="tag is-warning is-light">Generate using GPT</span>
          </b-switch>
        </b-field>

        <div v-if="localTemplate.useGpt">
          <b-field label="GPT instructions (promptSystem):" label-position="on-border">
            <b-input
              placeholder="Describe a scene from a fictional tarantino movie, film type, camera angle, and subject based on user input. "
              type="textarea"
              v-model="localTemplate.promptSystem"
              size="is-small"
            ></b-input>
          </b-field>
          <br />
        </div>
        <!-- 

    <b-field label="promptBasic   :">
      <b-input type="textarea" v-model="localTemplate.promptBasic" size="is-small"></b-input>
    </b-field>
     -->

        <b-field label="Prefix " label-position="on-border">
          <b-input type="textarea" v-model="localTemplate.promptPrefix" size="is-small"></b-input>
        </b-field>

        <b-field label=" Suffix:" label-position="on-border">
          <b-input type="textarea" v-model="localTemplate.promptSuffix" size="is-small"></b-input>
        </b-field>
      </div>
    </headerManage>

    <headerManage
      title="Engine"
      :sub="localTemplate.imageEngine || 'Default'"
      desc="Pick the engine that works best for your bot. "
      @save="save"
      :posting="posting"
    >
      <div>
        <b-field horizontal label="Base engine">
          <b-select v-model="localTemplate.imageEngine" placeholder="Select engine" :disabled="posting">
            <option :value="m.id" v-for="m in imageModels" :key="m.id">{{ m.label }}</option>

            <!--  
              <option value="" disabled>Other (soon)</option>-->
          </b-select>
        </b-field>

        <b-field label="Creativity">
          <b-slider v-model="localTemplate.imageCreativity" :min="1" style="max-width: 200px"></b-slider>
        </b-field>

        <b-field label="Quality:">
          <b-slider v-model="localTemplate.quality" :min="1" :max="100"></b-slider>
        </b-field>
      </div>
    </headerManage>

    <headerManage
      title="Output Size"
      :sub="localTemplate.imageSize || 'Flexible'"
      desc="Select the size of output images"
      @save="save"
      :posting="posting"
    >
      <div>
        <b-field horizontal label="Base engine">
          <b-select v-model="localTemplate.imageEngine" placeholder="Select engine" :disabled="posting">
            <option :value="m.id" v-for="m in imageModels" :key="m.id">{{ m.label }}</option>

            <!--  
              <option value="" disabled>Other (soon)</option>-->
          </b-select>
        </b-field>

        <b-field horizontal label="Creativity">
          <b-slider v-model="localTemplate.imageCreativity" :min="1" style="max-width: 200px"></b-slider>
        </b-field>
      </div>
    </headerManage>

    <hr />

    <h4 class="title is-5">Prompt</h4>

    <b-button @click="submitForm" type="is-primary">{{ isEditing ? "Save" : "Create" }}</b-button>
  </div>
</template>

<script>
import headerManage from "@/components/studio/headerManage";

const imageModels = [
  { id: "sdxl", label: "SD XL", icon: "fa fa-truck" },
  { id: "sd", label: "SD", icon: "fa fa-truck" },
  { id: "dalle", label: "Dalle", icon: "fa fa-car" },

  { id: "mj", label: "Midjourney", icon: "fa fa-truck", disabled: false },
  // Other models...
];
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    headerManage,
  },
  data() {
    return {
      imageModels,
      localTemplate: this.value, // create a local copy of prop value
    };
  },
  computed: {
    isEditing() {
      return !!this.localTemplate.id;
    },
  },
  methods: {
    submitForm() {
      this.save();
    },
    save() {
      this.$emit("submit", this.localTemplate);
    },
    updateField(field, event) {
      this.localTemplate[field] = event.target.value;
      this.$emit("input", this.localTemplate); // emit the updated localTemplate
    },
  },
  watch: {
    // Watch for changes from parent and update local state accordingly
    value(newVal) {
      this.localTemplate = { ...newVal };
    },
  },
};
</script>
